var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"pc indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mlist flex-col justify-start",staticStyle:{"height":"auto"}},[_c('top-a',{attrs:{"kind":``,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag}}),_c('div',{staticClass:"group_0_3 flex-col"},[_c('div',{staticClass:"g03 flex-col",style:(``)},[_c('img',{staticClass:"image_20",staticStyle:{"width":"1760px","height":"1px","margin":"0 auto"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"}}),_c('div',{staticClass:"g03in flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between",staticStyle:{"margin-top":"220px"}},[_c('div',{staticClass:"gtitles flex-col"},[_c('span',{staticClass:"text_13",staticStyle:{"color":"rgba(50, 50, 50, 1)","font-size":"80px","height":"80px","line-height":"80px"}},[_c('img',{staticStyle:{"height":"80px"},attrs:{"data-v-bdbf160e":"","src":"https://cdn.moheweb.com/vdb/lweb/imgs/title31.png"}})])]),_c('div',{staticClass:"gdes flex-col"},[_c('div',{staticClass:"textline g03tl",staticStyle:{"margin-top":"21px","color":"rgba(50, 50, 50, 1)","font-size":"24px","line-height":"24px"}},[_vm._v(" 了解前沿最新趣野趣事，关注更多展会相关资讯信息及赛事活动。 ")])])]),_c('div',{staticClass:"zlist flex-col"},[_vm._l((_vm.clist31),function(item,index){return [_c('div',{key:index,staticClass:"zitem flex-row justify-between",on:{"click":() => {
                          _vm.godetall(item.url, item.pname, item.kind, item.id);
                        }}},[_c('div',{staticClass:"zt"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.pname))]),_c('div',{staticClass:"des"},[_vm._v(_vm._s(item.des))]),_c('div',{staticClass:"zimg",style:(`background-image:url(${
                            item.imgurl.indexOf('https://') == -1
                              ? _vm.baseUrl + item.imgurl
                              : item.imgurl
                          })`)})]),_c('div',{staticClass:"zb"},[_c('div',{staticClass:"detall"},[_vm._v("了解详情")])]),_c('div',{staticClass:"ibackcolor"})])]})],2),_c('div',{staticClass:"pageselect",staticStyle:{"margin-top":"80px"}},[_c('a-pagination',{attrs:{"size":"small","total":_vm.ctotal,"page-size":_vm.cpageSize},on:{"change":_vm.PageonChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)]),_c('div',{staticClass:"backimg"})])]),_c('footer-a',{attrs:{"kind":``,"lag":_vm.lag}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }